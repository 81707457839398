import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors'; //eslint-disable-line
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
// import Avatar from '@mui/material/Avatar';

export default function AboutUs() {
  const { t } = useTranslation('aboutUs');
  const theme = useTheme();
  const { mode } = theme.palette;

  //material features
  const mock = [
    {
      title: 'Tickets',
      number: 30000,
      subtitle: t('tickets.subtitle'),
      prefix: '+',
      icon: (
        <img
          src="/img/Icons/iconTicket2.svg"
          style={{ width: 'auto', height: '150px', filter: mode === 'dark' ? 'invert(1)' : '' }}
        />
      ),
    },
    {
      title: t('listasDifusion.title'),
      number: 1000,
      subtitle: t('listasDifusion.subtitle'),
      prefix: '+',
      icon: (
        <img
          src="/img/Icons/iconMegaphone.svg"
          style={{ width: 'auto', height: '150px', filter: mode === 'dark' ? 'invert(1)' : ''}}
        />
      ),
    },
    {
      title: t('llamadas.title'),
      number: 250000,
      subtitle: t('llamadas.subtitle'),
      prefix: '+',
      icon: (
        <img
          src="/img/Icons/iconCall.svg"
          style={{ height: '150px', width: '120px', filter: mode === 'dark' ? 'invert(1)' : '' }}
        />
      ),
    },
    {
      title: t('comunicaciones.title'),
      number: 900000,
      subtitle:
      t('comunicaciones.subtitle'),
      prefix: '+',
      icon: (
        <img
          src="/img/Icons/iconChat.svg"
          style={{ height: '150px', width: '120px', filter: mode === 'dark' ? 'invert(1)' : '' }}
        />
      ),
    },
  ];
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // height: '27vh',
        minHeight: '250px',
        // maxWidth: '850px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      }}
    >
      {/* <Grid container direction={'column'}> */}
      {/* <Typography variant="body2" fontWeight={700} style={{ color: blue[700] }}>
        {t('aboutUs.name')}
      </Typography> */}
      <Typography
        variant="h2"
        style={{
          fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
          lineHeight: '1.2222222222222223',
          // color: '#132F4C',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        fontWeight={800}
      >
        {t('aboutUs.titlePartOne')}
        <span>{t('aboutUs.helpYou')}</span>
        {t('aboutUs.titlePartTwo')}
      </Typography>
      <Typography variant="body1">{t('aboutUs.subtitle')}</Typography>
      <Box paddingTop={5}>
        <Grid container marginTop={5} direction={isMd ? 'row' : 'column-reverse'}>
          <Grid item xs={12} md={12} data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Box>
              <Grid container >
                {mock.map((item, i) => (
                  <Grid key={i} item xs={12} md={3}>
                    <Box
                      marginBottom={2}
                      // bgcolor={theme.palette.primary.main}
                      // color={theme.palette.background.paper}
                    >
                      {item.icon}
                    </Box>
                    <Typography variant="h4" component="p" whiteSpace="nowrap">
                      {item.title}
                    </Typography>
                    <Typography
                      variant="h4"
                      color={'primary'}
                      gutterBottom
                      style={{
                        background:
                          'linear-gradient(to right, #007FFF, #0059B2)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }}
                    >
                      <Box fontWeight={600}>
                        <VisibilitySensor
                          onChange={(isVisible) =>
                            setViewPortVisibility(isVisible)
                          }
                          delayedCall
                        >
                          <CountUp
                            duration={2}
                            end={viewPortEntered ? item.number : 0}
                            start={0}
                            prefix={item.prefix}
                          />
                        </VisibilitySensor>
                      </Box>
                    </Typography>
                    <Typography component="p">{item.subtitle}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* </Grid> */}
    </Box>
  );
}
