const moduleHeroData = {
  name: 'Tickets',
  description:
    'El módulo permite dar un seguimiento adecuado a las tareas particulares de tu empresa, permitiendo mejorar la organización y agilizar las gestiones de las mismas.',
  primaryColor: '#888f88',
  secondaryColor: '#fde68a',
  backgroundColor: '#fffbeb',
  imgSource: 'https://trii.com.ar/img/TriiTickets.svg',
};

export default moduleHeroData;
