import React from 'react';
import { ModuleHero } from 'components';
import moduleHeroData from './utils/moduleHeroData';
// import functionsHeroData from './utils/functionsHeroData';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { HeroStyle1 } from 'components/ContentHero';
import { useTranslation } from 'react-i18next';

const TriiServiceView = () => {
  const theme = useTheme('@mui/material/Theme');
  const { mode } = theme.palette;
  const { t } = useTranslation('TriiService');
  return (
    <Main>
      <Box
        sx={
          mode === 'light'
            ? { backgroundColor: 'rgba(247, 250, 255, 1)' }
            : { backgroundColor: 'rgba(247, 250, 255, 0.1)' }
        }
      >
        <ModuleHero
          moduleName={moduleHeroData.name}
          moduleDescription={t('ModuleHeader.subtitle')}
          primaryColor={moduleHeroData.primaryColor}
          imgSource={moduleHeroData.imgSource}
        />
        <Container>
          <Grid id="info-section" container spacing={8}>
            {/* {functionsHeroData.map((contentObject, index) => (
              <HeroStyle1 {...contentObject} id={index} key={index} />            
              ))}         */}
            <HeroStyle1
              title={t('contentGestion.title')}
              subtitle={t('contentGestion.subtitle')}
              directionTitle={'left'}
            />
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default TriiServiceView;
