/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom'; //eslint-disable-line

import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';


const FunctionsHomeView = () => {
  // const theme = useTheme();
  const { t } = useTranslation('indexView');
  const theme = useTheme();
  const { mode } = theme.palette;
  const mock = [
    {
      title: t('functionsHomeView.multiChannelTitle'),
      description: t('functionsHomeView.multiChannelSubtitle'),
      icon: '/img/Icons/Functions/global-network.png',
    },
    {
      title: 'Bots',
      description:
      t('functionsHomeView.botsSubtitle'),
      icon: '/img/Icons/Functions/robotic.png',
    },
    {
      title: 'API',
      description:
      t('functionsHomeView.apiSubtitle'),
      icon: '/img/Icons/Functions/api.png',
    },
    {
      title: 'Dashboard',
      description:
      t('functionsHomeView.dashboardSubtitle'),
      icon: '/img/Icons/Functions/dashboard.png',
    },
    {
      title: 'Reportes',
      description:
      t('functionsHomeView.reportsSubtitle'),
      icon: '/img/Icons/Functions/report.png',
    },
    {
      title: 'IVR',
      description:
      t('functionsHomeView.ivrSubtitle'),
      icon: '/img/Icons/Functions/ivr2.png',
    },
  ];
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h2"
          style={{
            fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
            lineHeight: '1.2222222222222223',
            marginTop: '10px',
            marginBottom: '10px',
          }}
          fontWeight={800}
        >
          {t('functionsHomeView.title')}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              // component={Card}
              padding={4}
              borderRadius={2}
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box
                    component="img"
                    width={35}
                    height={35}
                    marginBottom={2}
                    sx={{ alignSelf: 'center', filter: mode === 'dark' ? 'invert(1)' : ''}}
                    // bgcolor={theme.palette.primary.main}
                    // color={theme.palette.background.paper}
                    src={item.icon}
                  ></Box>
                  <Typography
                    variant={'h4'}
                    color={'primary'}
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                      textAlign: 'center',
                      cursor: 'default',
                      marginLeft: '25px'

                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Typography color="text.secondary">
                  {item.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default FunctionsHomeView;
