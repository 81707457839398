const moduleHeroData = {
  name: 'Llamadas',
  description:
    'Integra las comunicaciones de audio a través de nuestro IVR y/o softphone SIP',
  primaryColor: '#e1da23',
  secondaryColor: '#fde68a',
  backgroundColor: '#fffbeb',
  imgSource: '/img/triiModules/TriiCall.svg',

};

export default moduleHeroData;
