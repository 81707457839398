import { useState } from 'react';

const useField = (type) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onError = (errorMsg) => {
    setError(true);
    setHelperText(errorMsg);
  };

  const onSuccess = () => {
    setError(false);
    setHelperText('');
  };

  return {
    actions: { onError, onSuccess },
    atributes: { error, helperText, type, value, onChange },
  };
};

export default useField;
