import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import Typography from '@mui/material/Typography';


export default function InfoListItem({ item, index, mockLenght }) {
  const theme = useTheme();
  return (
    <ListItem sx={{ alignItems: 'unset' }}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <ListItemIcon sx={{ minWidth: 'auto' }}>
          <Box
            component={Avatar}
            width={40}
            height={40}
            marginBottom={2}
            bgcolor={'#fa8080'}
          >
            {index + 1}
          </Box>
        </ListItemIcon>
        <Box
          display={index === mockLenght - 1 ? 'none' : 'flex'}
          flex={'1 1 0%'}
          borderRight={`1px solid ${theme.palette.divider}`}
        />
      </Box>
      <Box marginLeft={2}>
        <ListItemText
          primary={item}
          // secondary={item}
          primaryTypographyProps={{ fontWeight: 700 }}
          sx={{minHeight:'10vh'}}
        />
        <Box marginTop={2}>
          {/* <Typography variant={'subtitle2'}>{item}</Typography> */}
        </Box>
      </Box>
    </ListItem>
  );
}

InfoListItem.propTypes = {
  item: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  mockLenght: PropTypes.number.isRequired,
};
