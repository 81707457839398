const moduleHeroData = {
  name: 'Chat',
  description:
    'Mejora la atención al cliente agilizando la respuesta de tus conversaciones a través de canales unificados, brindando una atención mas personalizada',
  primaryColor: '#f87171',
  secondaryColor: '#fde68a',
  backgroundColor: '#fffbeb',
  imgSource: 'https://trii.com.ar/img/TriiChat.svg',

};

export default moduleHeroData;
