

const moduleHeroData = {
  name: 'Contactos',
  description:
    'Gestione todos sus contactos de manera organizada.',
  primaryColor: '#ffb546',
  secondaryColor: '#fde68a',
  backgroundColor: '#fffbeb',
  imgSource: '/img/triiModules/TriiContacts.svg',
};

export default moduleHeroData;
