import React from 'react';

import {
  IndexView,
  SignInView,
  SignUpView,
  ResetPasswordView,
  ContactView,
  TriiChatView,
  TriiCollectionView,
  TriiContactsView,
  TriiLlamadasView,
  TriiMarketingView,
  TriiServiceView,
  TriiTeamView,
  TriiTicketsView,
  FuncionesView,
  ProximamenteView,
} from 'views';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <IndexView {...params} />,
  },
  {
    path: '/signin',
    renderer: (params = {}) => <SignInView {...params} />,
  },
  {
    path: '/signup',
    renderer: (params = {}) => <ProximamenteView {...params} />,
  },
  {
    path: '/forgotpassword',
    renderer: (params = {}) => <ResetPasswordView {...params} />,
  },
  {
    path: '/contacto',
    renderer: (params = {}) => <ContactView {...params} />,
  },
  {
    path: '/funciones',
    renderer: (params = {}) => <FuncionesView {...params} />,
  },
  {
    path: '/modulos/triichat',
    renderer: (params = {}) => <TriiChatView {...params} />,
  },
  {
    path: '/modulos/triicontactos',
    renderer: (params = {}) => <TriiContactsView {...params} />,
  },
  {
    path: '/modulos/triicobranzas',
    renderer: (params = {}) => <TriiCollectionView {...params} />,
  },
  {
    path: '/modulos/triimarketing',
    renderer: (params = {}) => <TriiMarketingView {...params} />,
  },
  {
    path: '/modulos/triillamadas',
    renderer: (params = {}) => <TriiLlamadasView {...params} />,
  },
  {
    path: '/modulos/triiservice',
    renderer: (params = {}) => <TriiServiceView {...params} />,
  },
  {
    path: '/modulos/triiteam',
    renderer: (params = {}) => <TriiTeamView {...params} />,
  },
  {
    path: '/modulos/triiticket',
    renderer: (params = {}) => <TriiTicketsView {...params} />,
  },
  {
    path: '/signuptest',
    renderer: (params = {}) => <SignUpView {...params} />,
  },
];

export default routes;
