import React from 'react';
// import { Hero } from './components';
import { ModuleHero } from 'components';
import moduleHeroData from './utils/moduleHeroData';
// import functionsHeroData from './utils/functionsHeroData';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { HeroStyle1 } from 'components/ContentHero';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const TriiTicketsView = () => {
  const theme = useTheme('@mui/material/Theme');
  const { mode } = theme.palette;
  const { t } = useTranslation('TriiTickets');

  return (
    <Main>
      <Box
        sx={
          mode === 'light'
            ? { backgroundColor: 'rgba(247, 250, 255, 1)' }
            : { backgroundColor: 'rgba(247, 250, 255, 0.1)' }
        }
      >
        <ModuleHero
          moduleName={moduleHeroData.name}
          moduleDescription={t('ModuleHeader.subtitle')}
          primaryColor={moduleHeroData.primaryColor}
          imgSource={moduleHeroData.imgSource}
        />
        <Container>
          <Grid id="info-section" container spacing={8}>
            <HeroStyle1
              title={t('contentGestionTickets.title')}
              subtitle={t('contentGestionTickets.subtitle')}
              directionTitle={'left'}
            />
            <Divider className="dividerContentModule"></Divider>

            <HeroStyle1
              title={t('contentCaracteristicas.title')}
              subtitle={t('contentCaracteristicas.subtitle')}
              directionTitle={'right'}
            />
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default TriiTicketsView;
