/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const Features = () => {
  const { t } = useTranslation('indexView'); //eslint-disable-line

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // const mock = [
  //   {
  //     title: t('home.descriptionAdviceTitle'),
  //     subtitle: t('home.descriptionAdviceSubtitle'),
  //   },
  // ];
  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box marginBottom={4}>
            <Typography sx={{ fontWeight: 700 }} variant={'h1'} style={{fontSize: 'clamp(1.5rem, 6.9643rem + 1.4286vw, 32.25rem)'}}>
              Trii
            </Typography>
            <Typography sx={{ fontWeight: 700 }} variant={'h2'} style={{fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)'}}>
              {t('home.title')}
            </Typography>
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              {t('home.subtitle')}
            </Typography>
            {/* <Typography component={'p'} color={'text.secondary'}>
              {t('home.descriptionText')}
            </Typography> */}
          </Box>
          {/* <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid key={i} item xs={12} md={12}>
                <Typography variant="h6" color={'primary'} gutterBottom>
                  {item.title}
                </Typography>
                <Typography color="text.secondary" component="p">
                  {item.subtitle}
                </Typography>
              </Grid>
            ))}
          </Grid> */}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          display="flex"
          sx={{
            paddingRight: { xs: '0px', md: '32px' },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <Box
              component={CardMedia}
              height={1}
              width={1}
              minHeight={300}
              image="/img/comunicUnif.jpeg"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
