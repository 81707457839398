import React from 'react';
import { Hero } from './components';
import { ModuleHero } from 'components';
import moduleHeroData from './utils/moduleHeroData';
// import functionsHeroData from './utils/functionsHeroData';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { HeroStyle1 } from 'components/ContentHero';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const TriiChatView = () => {
  const theme = useTheme('@mui/material/Theme');
  const { mode } = theme.palette;
  const { t } = useTranslation('TriiChat');

  const listMock = [
    {
      text: [
        t('list1.text1'),
  
        t('list1.text2'),
  
        t('list1.text3'),
  
        t('list1.text4')   
        
  
      ],
      imageUrl: '/img/converNotebook4.png',
  
    },
    {
      text: [
        t('list2.text1'),
  
        t('list2.text2'),
  
        t('list2.text3'),
  
        t('list2.text4')
      ],
      imageUrl: '/img/converNotebook4.png',
    },
    
  ];
  
  return (
    <Main>
      <Box
        sx={
          mode === 'light'
            ? { backgroundColor: 'rgba(247, 250, 255, 1)' }
            : { backgroundColor: 'rgba(247, 250, 255, 0.1)' }
        }
      >
        <ModuleHero
          moduleName={t('ModuleHeader.title')}
          moduleDescription={t('ModuleHeader.subtitle')}
          primaryColor={moduleHeroData.primaryColor}
          imgSource={moduleHeroData.imgSource}
        />
        <Container>
          <Grid id="info-section" container spacing={8}>
            <HeroStyle1
              title={t('contentConversacionesUnificadas.title')}
              subtitle={t('contentConversacionesUnificadas.subtitle')}
              directionTitle={'left'}
            />
            <Divider className="dividerContentModule"></Divider>
            <HeroStyle1
              title={t('contentFunciones.title')}
              subtitle={t('contentFunciones.subtitle')}
              directionTitle={'right'}
            />
            <Divider className="dividerContentModule"></Divider>

            {listMock.map((contentObject, index) => (
              <>
                <Hero {...contentObject} id={index} key={index} />
                {index === 0 && (
                  <Divider className="dividerContentModule"></Divider>
                )}
              </>
            ))}
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};
export default TriiChatView;
