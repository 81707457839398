import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  VerificationCodeForm,
  FormNotificationInfo,
  VerificationSuccessBox,
} from 'components';
import { SignInForm } from './components';

export default function SignInFlow({ stage, setStage, emailField }) {
  const { t } = useTranslation(['signIn', 'accountVerification']);
  return (
    <>
      {stage === 'signIn' && (
        <SignInForm setStage={setStage} emailField={emailField} />
      )}
      {stage === 'emailVerification' && (
        <FormNotificationInfo
          infoMsg={t('verify.checkYourInbox', { ns: 'signIn' })}
        />
      )}
      {stage === 'emailVerification' && (
        <VerificationCodeForm
          setStage={setStage}
          email={emailField.atributes.value}
        />
      )}
      {stage === 'success' && (
        <VerificationSuccessBox
          successMsg={t('success.verifyAccount', { ns: 'accountVerification' })}
          redirectLink={false}
        />
      )}
    </>
  );
}

SignInFlow.propTypes = {
  stage: PropTypes.string.isRequired,
  setStage: PropTypes.func.isRequired,
  emailField: PropTypes.object.isRequired,
};
