const moduleHeroData = {
  name: 'Marketing',
  description:
    'Una herramienta de envío masivo para afianzar las relaciones de la empresa con sus clientes y fortalecer el marketing a traves de las redes sociales.',
  primaryColor: '#ec4899',
  secondaryColor: '#a5f3fc',
  backgroundColor: '#ecfeff',
  imgSource: 'https://trii.com.ar/img/TriiMarketing.svg',
};

export default moduleHeroData;
