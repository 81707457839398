const moduleHeroData = {
  name: 'Cobranzas',
  description:
    'Realiza una cobranza eficiente afianzando la relación con tus deudores y agiliza las gestiones de cobro de manera más simple.',
  primaryColor: '#47749d',
  secondaryColor: '#fde68a',
  backgroundColor: '#fffbeb',
  imgSource: 'https://trii.com.ar/img/TriiCobranzas.svg',

};

export default moduleHeroData;
