/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

const Form = () => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    nombre: yup
      .string()
      .trim()
      .min(2, t('form.enterValidName'))
      .max(50, t('form.enterValidName'))
      .required(t('form.specifyFirstName')),
    // lastName: yup
    //   .string()
    //   .trim()
    //   .min(2, t('form.enterValidName'))
    //   .max(50, t('form.enterValidName'))
    //   .required(t('form.specifyLastName')),
    email: yup
      .string()
      .trim()
      .email(t('form.validEmailAdress'))
      .required(t('form.emailIsRequired')),
    phone: yup
      .string()
      .trim()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
        t('form.validPhoneNumber'),
      ),
    // budget: yup.string().required('Please specify your project budget'),
    message: yup.string().trim().max(500, t('form.maxMessageContent')),
  });

  const initialValues = {
    nombre: '',
    // lastName: '',
    email: '',
    phone: '',
    // budget: '',
    message: '',
  };

  const onSubmit = (data) => {
    fetch('https://gestion.trii.com.ar/api/v1/canales/forms/new', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        console.log(response);
        if (response.status == 200) {
          let rta = await response.json();
          console.log(rta);
          // e.target.reset();
        } else {
          console.log('error API');
        }
      })
      .catch(function (error) {
        console.log('errorCatch', error);
      });
    return data;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box paddingBottom={{ xs: 4, sm: 4 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          component={Grid}
          marginBottom={{ xs: 10, sm: 0 }}
          container
          spacing={4}
        >
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ height: 45 }}>
              {/* Please tell us your name * */}
              {t('form.askName') + '*'}
            </Typography>
            <TextField
              label={t('global.name')}
              variant="outlined"
              name={'nombre'}
              fullWidth
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={
                formik.touched.nombre && Boolean(formik.errors.nombre)
              }
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ height: 45 }}>
              {t('form.askName') + '*'}
            </Typography>
            <TextField
              label={t('form.lastName')}
              variant='outlined'
              name={'lastName'}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ height: 45 }}>
              {t('form.askEmail') + '*'}
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ height: 45 }}>
              {t('form.askPhoneNumber')}
            </Typography>
            <TextField
              label={t('form.phoneNumber')}
              variant="outlined"
              name={'phone'}
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ height: 45 }}>
              {t('form.message')}
            </Typography>
            <TextField
              label={t('form.message')}
              variant="outlined"
              name={'message'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Button size={'large'} variant={'contained'} type={'submit'} disabled={!(formik.isValid && formik.dirty)}>
              {t('form.send')}
            </Button>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
