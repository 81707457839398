import React from 'react';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
// import { Content, Headline } from './components';

const FuncionesView = () => {
  // const theme = useTheme();
  return (
    <Main>
      <Box sx={{backgroundColor: 'rgb(247, 250, 255)'}}>
        <Container>
          {/* <Headline /> */}
        </Container>
        <Container maxWidth={800}>
          {/* <Content /> */}
        </Container>
      </Box>
    </Main>
  );
};

export default FuncionesView;
