import React from 'react';
import { InfoListItem } from './components';
import PropTypes from 'prop-types';
// import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import Box from '@mui/material/Box';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';

export default function InfoList({ info }) {
  return (
    // <List style={{ marginBottom: 3 }}>
    //   {text.map((item, index) => (
    //     <ListItem key={index}>
    //       <EmojiObjectsRoundedIcon
    //         sx={{ color: 'rgb(71, 178, 228)', alignSelf: 'baseline' }}
    //       />
    //       <Typography marginLeft={1} variant="body1" color={'textSecondary'}>
    //         <span style={{ color: 'rgb(71, 178, 228)', fontWeight: '600' }}>
    //           {index + 1})
    //         </span>
    //         {item}
    //       </Typography>
    //     </ListItem>
    //   ))}
    // </List>
    <List
      sx={{
        width: '100%',
        padding: 4,
        borderRadius: 2,
      }}
    >
      {info.map((item, i) => (
        <InfoListItem key={i} item={item} index={i} mockLenght={info.length} />
      ))}
    </List>
  );
}

InfoList.propTypes = {
  info: PropTypes.array.isRequired,
};
