const moduleHeroData = {
  name: 'Team',
  description:
    'Mejora las comunicaciones internas de la empresa generando trabajo en equipo a través de la resolución conjunta de tareas por parte de agentes pertenecientes a diferentes aéreas.',
  primaryColor: '#85d384',
  secondaryColor: '#fde68a',
  backgroundColor: '#fffbeb',
  imgSource: 'https://trii.com.ar/img/TriiTeam.svg',

};

export default moduleHeroData;
