import React, { useState, useEffect } from 'react';
import { InfoList } from './components';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Hero({ text, imageUrl, id }) {
  const [show, setShow] = useState(false);
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Grow unmountOnExit in={show}>
      <Grid
        item
        container
        spacing={4}
        direction={id % 2 === 0 ? 'row' : 'row-reverse'}
      >
        <Grid
          item
          container
          alignItems={imageUrl !== '' ? 'center' : 'start'}
          justifyContent={'center'}
          xs={12}
          md={6}
        >
          <Box
            component={'img'}
            height={'auto'}
            //width={1}
            src={imageUrl}
            alt="..."
            borderRadius={2}
            maxWidth={isMd ? '95%' : 300}
          ></Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <InfoList info={text} />
        </Grid>
      </Grid>
    </Grow>
  );
}

Hero.propTypes = {
  text: PropTypes.array.isRequired,
  imageUrl: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};
